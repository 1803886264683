import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ActionButtons from "../../../components/action_buttons";
import config from "../../../../../config";
import { toast } from "react-toastify";

const CountriesForm = ({ onHide, formData, onSave, isEdit, isView, initialData, GetEmployeeCountries, employee }) => {
  const purposeOptions = [
    { label: "Personal Family Trip", value: "Personal Family Trip" },
    // Add more options as needed
  ];
  const token = localStorage.getItem('authToken');
  const typeOptions = [
    { label: "Private Trip", value: "Private Trip" },
  
  ];
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      employeeId: initialData?.employeeId || "",
      country: initialData?.country || "",
      tripType: initialData?.tripType || "",
      purpose: initialData?.purpose || "",
      startDate: initialData?.startDate ? new Date(initialData.startDate) : null,
      endDate: initialData?.endDate ? new Date(initialData.endDate) : null,
    },
    validationSchema: Yup.object({
      country: Yup.string().required("Country is required"),
      tripType: Yup.string().required("Trip Type is required"),
      purpose: Yup.string().required("Purpose is required"),
      endDate: Yup.date()
      .typeError("Please enter a valid date")
      .required("End Date is required")
      .min(Yup.ref("startDate"), "End date cannot be before start date"),
    
      startDate: Yup.date()
      .typeError("Please enter a valid date")
      .required("Start date is required"),

    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);

        if (isEdit) {
          values.Id = initialData?.id;
          await fetch(`${config.baseUrl}api/EmployeeTrips/UpdateTrip`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          });
          toast.success("Updated successfully");
        } else {
          values.employeeId = employee;
          await fetch(`${config.baseUrl}api/EmployeeTrips/AddTrip`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          });
          toast.success("Added successfully");
        }
        onSave();
        setLoading(false);
        GetEmployeeCountries();
      } catch (error) {
        console.error("Error while submitting qualification:", error);
      } finally {
        // Close the modal regardless of the result
        onHide();
        setLoading(false);
      }
    },
  
  });


  return (
    <div className="main-form">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">

        <div className="field col-12 md:col-4">
        <label htmlFor="country">Country</label>
            <InputText
              id="country"
              placeholder="Enter"
              value={formik.values.country}
              onChange={formik.handleChange}
              disabled={isView} // Disable input if in view mode
            />
            {formik.touched.country && formik.errors.country ? (
              <small className="p-error">{formik.errors.country}</small>
            ) : null}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="tripType">Trip Type</label>
            <Dropdown
              id="tripType"
              options={typeOptions}
              placeholder="Select type"
              value={formik.values.tripType}
              onChange={(e) => formik.setFieldValue("tripType", e.value)}
              disabled={isView} // Disable input if in view mode
            />
            {formik.touched.tripType && formik.errors.tripType ? (
              <small className="p-error">{formik.errors.tripType}</small>
            ) : null}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="purpose">Purpose</label>
            <Dropdown
              id="purpose"
              options={purposeOptions}
              placeholder="Select Purpose"
              value={formik.values.purpose}
              onChange={(e) => formik.setFieldValue("purpose", e.value)}
              disabled={isView} // Disable input if in view mode
            />
            {formik.touched.purpose && formik.errors.purpose ? (
              <small className="p-error">{formik.errors.purpose}</small>
            ) : null}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="startingDate">Starting Date</label>
            <Calendar
              id="startingDate"
              placeholder="Enter"
              value={formik.values.startDate}
              onChange={(e) => formik.setFieldValue("startDate", e.value)}
              showIcon
              disabled={isView} // Disable input if in view mode
            />
            {formik.touched.startDate && formik.errors.startDate ? (
              <small className="p-error">{formik.errors.startDate}</small>
            ) : null}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="endingDate">Ending Date</label>
            <Calendar
              id="endingDate"
              placeholder="Enter"
              value={formik.values.endDate}
              onChange={(e) => formik.setFieldValue("endDate", e.value)}
              showIcon
              disabled={isView} // Disable input if in view mode
            />
            {formik.touched.endDate && formik.errors.endDate ? (
              <small className="p-error">{formik.errors.endDate}</small>
            ) : null}
          </div>
        </div>

        <div className="p mt-4 form-buttons">
          <ActionButtons
             loading={loading}
            onCancel={onHide}
            onSave={formik.handleSubmit}
            saveLabel={isEdit ? "Update Changes" : "Save Changes"}
            showSave={!isView} // Only show save button if not in view mode
            cancelLabel="Cancel"
          />
        </div>
      </form>
    </div>
  );
};

export default CountriesForm;
