import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import config from "../../../../config";
import Spinner from "../../components/spinner";

const LeaveData = () => {
  const [leaveDeatilsList, setLeaveDeatilsList] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const employee = location.state?.employee;

  const handleBackClick = () => {
    navigate("/leave");
  };
  const GetLeaveDetails = async () => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);

      const response = await fetch(
        ` ${config.baseUrl}api/Leaves/GetLeaveDetails/${employee.employeeId}`,
        {
          method: "Get",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setLeaveDeatilsList(data?.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetLeaveDetails();
  }, []);

  return (
    <>
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-6">
          <h5 className="pages-internal-heading">
            <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
              Leave Management
            </span>{" "}
            &gt; Leave Details
          </h5>
        </div>
      </div>
      {loading && (
        <div className="spinner-overlay">
          <Spinner />
        </div>
      )}

      <div className="leave-grid">
        {leaveDeatilsList?.map((leave, index) => (
          <div key={index} className="leave-card">
            <div className="leave-header">{leave?.leaveTypeName} Leaves
            </div>
            <div className="leave-body">
              <div className="leave-row">
                <span>Leave Type</span>
                <span className="leave-value">{leave?.leaveTypeName} Leaves</span>
              </div>
              <div className="leave-row">
                <span>Total Available</span>
                <span className="leave-value">{leave?.totalAvailable}</span>
              </div>
              <div className="leave-row">
                <span>Remaining</span>
                <span className="leave-value">{leave?.remaining}</span>
              </div>
              <div className="leave-row">
                <span>Total Availed</span>
                <span className="leave-value">{leave?.totalAvailed}</span>
              </div>
              {/* <div className="leave-row">
                <span>Leave Type</span>
                <span className="leave-value">{leave.leaveTypeId}</span>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default LeaveData;
