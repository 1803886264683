import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import LineChart from "./line-chart";
import BarChart from "./bar-chart";
import sun from "../../../../assets/assests/sun.png";
import employee from "../../../../assets/assests/Employee.png";
import early from "../../../../assets/assests/Early Arrival.png";
import time from "../../../../assets/assests/Time-off.png";
import present from "../../../../assets/assests/Present.png";
import absent from "../../../../assets/assests/Absent.png";
import late from "../../../../assets/assests/Late Arrival.png";
import add from "../../../../assets/assests/Add-grapgh.png";
import up from "../../../../assets/assests/Graph Up.png";
import down from "../../../../assets/assests/Graph down.png";
import down2 from "../../../../assets/assests/down.png";
import config from "../../../../config";
import moment from "moment";
import Spinner from "../../components/spinner";
import usePermissions from "../../components/hooks/usePermissions";
import BarChartRegion from "./bar-chartregion";

export default function DashboardScreen() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedPeriod, setSelectedPeriod] = useState("monthly");
  const [attendanceDetailByperiod, setAttendanceDetailByperiod] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [regionData, setRegionData] = useState([]);

  const permissions = usePermissions("Attendance");

  const [dashboardStats, setDashboardStats] = useState(null);

  const GetDashboardStats = async () => {
    const token = localStorage.getItem("authToken");
    const currentDay = moment().format("YYYY-MM-DD");

    const requestBody = {
      StartDate: currentDay,
      EndDate: currentDay,
    };
    setLoading(true);

    try {
      const response = await fetch(
        `${config.baseUrl}api/Dashboard/GetDashboardStats`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (data.status === 200) {
        // Set default values to avoid undefined data
        setDashboardStats(
          data.data || {
            totalEmployees: { totalCount: 0, newEmployeeCount: 0 },
            present: { totalCount: 0, decreaseRatio: 0 },
            absent: { totalCount: 0, increaseRatio: 0 },
            lateArrivals: { totalCount: 0, increaseRatio: 0 },
            earlyArrivals: { totalCount: 0, decreaseRatio: 0 },
            timeOf: { totalCount: 0, increaseRatio: 0 },
          }
        );
      }
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  const GetAttendanceDetailByperiod = async () => {
    const token = localStorage.getItem("authToken");
    try {
      // setLoading(true);
      const payload = {
        periodType: selectedPeriod,
      };

      const response = await fetch(
        `${config.baseUrl}api/Dashboard/GetAttendanceDetailByperiod`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const data = await response.json();
      setAttendanceDetailByperiod(data);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      // setLoading(false);
    }
  };

  const GetDepartmentAttendance = async () => {
    const token = localStorage.getItem("authToken");
    const currentDate = new Date().toISOString().split("T")[0];
    try {
      setLoading(true);
      const payload = {
        periodType: selectedPeriod,
      };

      const response = await fetch(
        `${config.baseUrl}api/Dashboard/GetDepartmentAttendance?date=${currentDate}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setDepartmentData(data);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  const GetRegionalAttendance = async () => {
    const token = localStorage.getItem("authToken");
    const currentDate = new Date().toISOString().split("T")[0];
    try {
      setLoading(true);

      const payload = {
        date: currentDate,
      };

      const response = await fetch(
        `${config.baseUrl}api/Dashboard/GetAttendanceByRegion`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setRegionData(data);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetDepartmentAttendance();
    GetDashboardStats();
    GetRegionalAttendance();
  }, []);

  useEffect(() => {
    // GetEmployee();
    GetAttendanceDetailByperiod("weekly");
  }, [selectedPeriod]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatTime = (time) => {
    return moment(time).format("hh:mm:ss A");
  };
  const date = moment().format("DD MMMM YYYY");

  const statsData = [
    {
      title: dashboardStats?.totalEmployees?.totalCount || 0,
      description: "Total Employees",
      smallText: `${
        dashboardStats?.totalEmployees?.newEmployeeCount || 0
      } new employees added!`,
      image: employee,
      icon: add,
    },
    {
      title: dashboardStats?.present?.totalCount || 0,
      description: "Present",
      smallText: `${
        dashboardStats?.present?.decreaseRatio || 0
      }% Less than yesterday`,
      image: present,
      icon: up,
    },
    {
      title: dashboardStats?.absent?.totalCount || 0,
      description: "Absent",
      smallText: `${
        dashboardStats?.absent?.increaseRatio || 0
      }% Increase than yesterday`,
      image: absent,
      icon: down,
    },
    {
      title: dashboardStats?.lateArrivals?.totalCount || 0,
      description: "Late Arrival",
      smallText: `${
        dashboardStats?.lateArrivals?.increaseRatio || 0
      }% Increase than yesterday`,
      image: late,
      icon: down,
    },
    {
      title: dashboardStats?.earlyArrivals?.totalCount || 0,
      description: "Early Arrival",
      smallText: `${
        dashboardStats?.earlyArrivals?.decreaseRatio || 0
      }% Less than yesterday`,
      image: early,
      icon: up,
    },
    {
      title: dashboardStats?.timeOf?.totalCount || 0,
      description: "Leave",
      smallText: `${
        dashboardStats?.timeOf?.increaseRatio || 0
      }% Increase than yesterday`,
      image: time,
      icon: down2,
    },
  ];

  const handleViewAttendanceClick = () => {
    navigate("/attendance");
  };

  const handleFilterApply = () => {
    GetAttendanceDetailByperiod(selectedPeriod);
  };

  return (
    <>
      <div className="dashboard">
        <div className="dashboard-screen">
          {/* Time Section */}
          <div className="time-section">
            <div className="time-card">
              <div className="time-header">
                <img src={sun} />
                <div className="content">
                  <h4>{formatTime(currentTime)}</h4>
                  <span>Realtime Insight</span>
                </div>
              </div>
              <div className="time-body">
                <p>
                  Today: <br />
                  <span>{date}</span>
                </p>
              </div>
              {["add", "edit", "delete", "view"].some(
                (key) => permissions?.[key]
              ) && (
                <Button
                  label="View Attendance"
                  className="attendance-button p-button-success"
                  onClick={handleViewAttendanceClick}
                />
              )}
            </div>
          </div>

          <div className="stats-section">
            <div className="grid">
              {statsData.map((stat, index) => (
                <div key={index} className="md:col-12 lg:col-4 mobile-graphs">
                  <Card
                    className="stat-card"
                    title={loading ? <Spinner /> : String(stat.title)}
                  >
                    <p>{stat.description}</p>
                    <small>
                      <img src={stat.icon} />
                      {loading ? <Spinner /> : stat.smallText}
                    </small>
                    <img
                      src={stat.image}
                      className={`${stat.icon} stat-icon`}
                    />
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="grid">
          {/* <div className="md:col-12 lg:col-6 mobile-graphs">
            <div className="card charts-card ">
              <div
                className="md:col-12 lg:col-12 flex flex-row"
                style={{ height: "35px" }}
              >
                <div className="md:col-8 lg:col-4">
                  <h6>Attendance Status</h6>
                </div>
                <div className="md:col-4 lg:col-8 flex flex-column justify-content-center align-items-end">
                  <div className="period-selection mt-2 flex">
                    <div className="p-field-radiobutton flex align-items-center">
                      <input
                        type="radio"
                        id="daily"
                        name="period"
                        value="daily"
                        onChange={(e) => setSelectedPeriod(e.target.value)}
                        checked={selectedPeriod === "daily"}
                      />
                      <label htmlFor="daily" className="custom-radio">
                        Daily
                      </label>
                    </div>

                    <div className="p-field-radiobutton flex align-items-center">
                      <input
                        type="radio"
                        id="weekly"
                        name="period"
                        value="weekly"
                        onChange={(e) => setSelectedPeriod(e.target.value)}
                        checked={selectedPeriod === "weekly"}
                      />
                      <label htmlFor="weekly" className="custom-radio">
                        Weekly
                      </label>
                    </div>

                    <div className="p-field-radiobutton flex align-items-center">
                      <input
                        type="radio"
                        id="monthly"
                        name="period"
                        value="monthly"
                        onChange={(e) => setSelectedPeriod(e.target.value)}
                        checked={selectedPeriod === "monthly"}
                      />
                      <label htmlFor="monthly" className="custom-radio">
                        Monthly
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <LineChart attendanceDetailByperiod={attendanceDetailByperiod} />
            </div>
          </div> */}
          <div className="md:col-12 lg:col-6 mobile-graphs">
            <div className="card charts-card">
              <h6>Department Attendance </h6>
              <BarChart departmentData={departmentData} />
            </div>
          </div>
          <div className="md:col-12 lg:col-6 mobile-graphs">
            <div className="card charts-card">
              <h6>Regional Attendance </h6>
              <BarChartRegion regionData={regionData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
