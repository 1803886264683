import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ActionButtons from "../../components/action_buttons";
import { InputTextarea } from "primereact/inputtextarea";
import { ConfirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import tick from "../../../../assets/assests/tick-success.png";
import config from "../../../../config";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditAddForm = ({ onHide, editable, setRes, rowData, setShowDialog }) => {
  const navigate = useNavigate();

  // const [showDialog, setShowDialog] = useState(false);
  const [apiRes, setApiRes] = useState();
  const [value, setValue] = useState("");
  const [fileName, setFileName] = useState("");
  const [employeeList, setEmployeeList] = useState();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      name: "",
      ...(editable ? { id: rowData.id } : {}),
    },
    onSubmit: async (data) => {
      const token = localStorage.getItem("authToken");
      setLoading(true);

      try {
        if (editable) {
          const response = await axios.put(
            `${config.baseUrl}api/Posts/UpdatePost`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          setApiRes(response);
          toast.success("updated Succesfully");
          setRes(response);
        } else {
          const response = await axios.post(
            `${config.baseUrl}api/Posts/AddPost`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          setApiRes(response);
          setRes(response);
          toast.success("Added Successfully");
        }
        setShowDialog(false);
        setLoading(true);

      } catch (error) {
        toast.error("Something went wrong");
      }
    },
  });
  const id = localStorage.getItem("userId");

  const GetEmployee = async () => {
    const token = localStorage.getItem("authToken");
    setLoading(true);

    try {
      const response = await fetch(`${config.baseUrl}api/Posts/GetAll`, {
        method: "Get",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setEmployeeList(data);
      setLoading(false);
    } catch (error) {}
  };
  useEffect(() => {
    GetEmployee();
  }, []);

  useEffect(() => {
    if (editable) {
      formik.setFieldValue("name", rowData.name);
    }
  }, [editable, rowData]);

  return (
    <>
      <ToastContainer />

      <div className="main-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid formgrid grid">
            <div className="field md:col-12">
              <label htmlFor="purpose">
                Designation Name{" "}
                <span className="Staric-Custom text-danger"> *</span>
              </label>
              <InputText
                id="name"
                placeholder="Enter Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                name="name"
              />

              {formik.touched.name && formik.errors.name ? (
                <div className="error">{formik.errors.name}</div>
              ) : null}
            </div>
          </div>
         

          <div className="p mt-4 form-buttons">
            <ActionButtons
               loading={loading}
              onCancel={onHide}
              onSave={formik.handleSubmit}
              saveLabel={editable ? "Update Changes" : "Save Changes"}
              showSave={true}
              cancelLabel="Cancel"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default EditAddForm;
