import React from "react";
import { Button } from "primereact/button";
import editIcon from "../../../../../assets/assests/edit.png";
import eyeIcon from "../../../../../assets/assests/eye.png";
import { formatTo12Hour } from "../../../components/helper";
import { Tag } from "primereact/tag";

// Action template function
export const actionTemplate = (
  rowData,
  openModal,
  type,
  setIsEdit,
  setIsView
) => {
  return (
    <div className="action-buttons flex">
      <Button
        className="p-button-rounded p-button-edit p-0 mr-3 action-images"
        aria-label="view"
        onClick={() => {
          setIsView(true);
          setIsEdit(false);
          // openModal('Details', rowData, type)
          openModal("Details", rowData, type, false, true);
        }}
      >
        <img src={eyeIcon} alt="Eye" />
      </Button>
      <Button
        className="p-button-edit p-0 mr-3"
        aria-label="Edit"
        onClick={() => {
          setIsView(false);
          setIsEdit(true);
          // openModal('Edit', rowData, type)
          openModal("Edit", rowData, type, true, false);
        }}
      >
        <img src={editIcon} alt="Edit" />
      </Button>
    </div>
  );
};

export const employeeColumns = [
  { field: "employeeNumber", header: "Emp No", body: (rowData) => rowData.employeeNumber || "--" },
  { field: "employeeName", header: "Name", body: (rowData) => rowData.employeeName || "--" },
  { field: "cnic", header: "CNIC", body: (rowData) => rowData.cnic || "--" },
  {
    field: "passportNumber",
    header: "Passport",
    body: (rowData) => rowData.passportNumber || "--",
  },
  {
    field: "nationalTaxNumber",
    header: "NTN",
    body: (rowData) => rowData.nationalTaxNumber || "--",
  },
  {
    field: "religion",
    header: "Religion",
    body: (rowData) => rowData.religion || "--",
  },
  { field: "role", header: "Role/Post Held", body: (rowData) => rowData.role || "--" },
];


export const qualificationColumns = [
  // { field: "employeeName", header: "Employee Name" },
  // { field: "cnic", header: "CNIC" },
  { field: "academic", header: "Academic" },
  { field: "specialization", header: "Specialization" },
  { field: "university", header: "University" },
  { field: "country", header: "Country" },
  {
    header: "Action",
    body: actionTemplate,
  },
];

export const countriesColumns = [
  { field: "country", header: "Country" },
  { field: "purpose", header: "Purpose" },
  {
    header: "Duration from​",
    body: (rowData) => formatTo12Hour(rowData.startDate),
  },
  {
    header: "Duration to​",
    body: (rowData) => formatTo12Hour(rowData.endDate),
  },
  {
    header: "Action",
    body: actionTemplate,
  },
];

export const servicesColumns = (depList) => [
  { field: "serviceYear", header: "Service year" },
  { field: "postHeldAndGrade", header: "Post Held & Grade" },
  {
    field: "departmentId",
    header: "Department​",
    body: (rowData) => {
      const department = depList.find(
        (dep) => dep.value === rowData.departmentId
      );
      return department ? department.label : "Unknown Department";
    },
  },
  { field: "station", header: "Station" },
  {
    header: "Post Held From​",
    body: (rowData) => formatTo12Hour(rowData.from),
  },
  {
    header: "Post Held To",
    body: (rowData) => formatTo12Hour(rowData.to),
  },
  {
    header: "Action",
    body: actionTemplate,
  },
];

export const trainingColumns = [
  // { field: "", header: "Employee Name" },
  { field: "trainingType", header: "Training Type" },
  { field: "institution", header: "Institution" },
  {
    header: "Duration from​",
    body: (rowData) => formatTo12Hour(rowData.startDate),
  },
  {
    header: "Duration to​",
    body: (rowData) => formatTo12Hour(rowData.endDate),
  },

  { field: "country", header: "Country​" },
  { field: "city", header: "City​" },
  {
    header: "Action",
    body: actionTemplate,
  },
];

export const attendanceColumns = [
  // {
  //   field: "shift",
  //   header: "SHIFT",
  //   body: (rowData) => (rowData.shift ? rowData.shift : "--"),
  // },
  // {
  //   field: "shiftIncharge",
  //   header: "SHIFT INCHARGE",
  //   body: (rowData) => (rowData.shiftIncharge ? rowData.shiftIncharge : "--"),
  // },
  {
    field: "date",
    header: "DATE",
    body: (rowData) => (rowData.date ? rowData.date : "--"),
  },
  {
    field: "checkIn",
    header: "CHECK-IN",
    body: (rowData) => (rowData.checkIn ? rowData.checkIn : "--"),
  },
  {
    field: "checkOut",
    header: "CHECK-OUT",
    body: (rowData) => (rowData.checkOut ? rowData.checkOut : "--"),
  },
  {
    field: "status",
    header: "STATUS",
    body: (rowData) => {
      const status = rowData.status;
      if (status === "Present") {
        return <Tag className="custom-success-tag" value="Present" />;
      } else if (status === "Late") {
        return <Tag className="custom-warning-tag" value="Late" />;
      } else if (status === "Leave") {
        return <Tag className="custom-danger-tag" value="Leave" />;
      } else if (status === "Early") {
        return <Tag className="custom-info-tag" value="Early" />;
      } else {
        return <Tag className="custom-info-tag" value={status} />;
      }
    },
  },
];
