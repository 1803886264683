import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ActionButtons from "../../../components/action_buttons";
import { useFormik } from "formik";
import * as Yup from "yup";
import config from "../../../../../config";
import { toast } from "react-toastify";
// Validation schema for the form
const validationSchema = Yup.object({
  academic: Yup.string().required("Academic is required"),
  specialization: Yup.string().required("Specialization is required"),
  university: Yup.string().required("University is required"),
  country: Yup.string().required("Country is required"),
  //   from: Yup.date()
  //   .required("Start date is required"),
  // to: Yup.date()
  //   .required("End date is required")
  //   .min(Yup.ref("from"), "End date cannot be before start date"),

  to: Yup.date()
    .typeError("Please enter a valid date")
    .required("End Date is required")
    .min(Yup.ref("from"), "End date cannot be before start date"),

  from: Yup.date()
    .typeError("Please enter a valid date")
    .required("Start date is required"),
});
const token = localStorage.getItem("authToken");
const QualificationForm = ({
  onHide,
  onSave,
  isEdit,
  isView,
  initialData,
  GetEmployeeQualifications,
  employee,
}) => {
const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      academic: initialData?.academic || "",
      specialization: initialData?.specialization || "",
      university: initialData?.university || "",
      country: initialData?.country || "",
      from: initialData?.from ? new Date(initialData.from) : null,
      to: initialData?.to ? new Date(initialData.to) : null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const payload = {
        ...values,
        from: values.from ? values.from.toISOString() : "",
        to: values.to ? values.to.toISOString() : "",
      };

      try {
        setLoading(true);

        if (isEdit) {
          payload.Id = initialData?.id;

          // Make the PUT request for update
          await fetch(
            `${config.baseUrl}api/EmployeeQualifications/UpdateQualification`,
            {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            }
          );
          toast.success("Updated successfully");
        } else {
          // Include employeeId only in "add" case
          payload.employeeId = employee;

          // Make the POST request for add
          await fetch(
            `${config.baseUrl}api/EmployeeQualifications/AddQualification`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            }
          );
          toast.success("Added successfully");
        }

        // After successful API call, fetch updated qualifications and close the modal
        GetEmployeeQualifications();
        setLoading(false);
        onSave();
      } catch (error) {
        console.error("Error while submitting qualification:", error);
      } finally {
        // Close the modal regardless of the result
        onHide();
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (isEdit || isView) {
      formik.setValues({
        academic: initialData?.academic || "",
        specialization: initialData?.specialization || "",
        university: initialData?.university || "",
        country: initialData?.country || "",
        from: initialData?.from ? new Date(initialData?.from) : null,
        to: initialData?.to ? new Date(initialData?.to) : null,
      });
    }
  }, [initialData]);

  return (
    <div className="main-form">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
        <div className="field col-12 md:col-4">
        <label htmlFor="academic">Academic</label>
            <InputText
              id="academic"
              name="academic"
              value={formik.values.academic}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter"
              disabled={isView}
            />
            {formik.touched.academic && formik.errors.academic && (
              <small className="p-error">{formik.errors.academic}</small>
            )}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="specialization">Specialization</label>
            <InputText
              id="specialization"
              name="specialization"
              value={formik.values.specialization}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter"
              disabled={isView}
            />
            {formik.touched.specialization && formik.errors.specialization && (
              <small className="p-error">{formik.errors.specialization}</small>
            )}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="country">Country</label>
            <InputText
              id="country"
              name="country"
              value={formik.values.country}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter"
              disabled={isView}
            />
            {formik.touched.country && formik.errors.country && (
              <small className="p-error">{formik.errors.country}</small>
            )}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="university">University</label>
            <InputText
              id="university"
              name="university"
              value={formik.values.university}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter"
              disabled={isView}
            />
            {formik.touched.university && formik.errors.university && (
              <small className="p-error">{formik.errors.university}</small>
            )}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="from">Started From</label>
            <Calendar
              id="from"
              name="from"
              value={formik.values.from}
              onChange={(e) => formik.setFieldValue("from", e.value)}
              onBlur={formik.handleBlur}
              showIcon
              placeholder="Enter"
              disabled={isView}
            />
            {formik.touched.from && formik.errors.from && (
              <small className="p-error">{formik.errors.from}</small>
            )}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="to"> To</label>
            <Calendar
              id="to"
              name="to"
              value={formik.values.to}
              onChange={(e) => formik.setFieldValue("to", e.value)}
              onBlur={formik.handleBlur}
              showIcon
              placeholder="Enter"
              disabled={isView}
            />
            {formik.touched.to && formik.errors.to && (
              <small className="p-error">{formik.errors.to}</small>
            )}
          </div>
        </div>
        {!isView && (
          <div className="p mt-4 form-buttons">
            <ActionButtons
               loading={loading}
              onCancel={onHide}
              onSave={formik.handleSubmit}
              saveLabel="Save Changes"
              showSave={true}
              cancelLabel="Cancel"
            />
          </div>
        )}
      </form>
    </div>
  );
};

export default QualificationForm;
