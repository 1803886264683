import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";

export default function BarChart({ departmentData }) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const labels = departmentData?.map((item) => item.x);
    const dataValues = departmentData?.map((item) => item.y);

    // Find the index of the maximum value to highlight that bar
    const maxIndex = dataValues.indexOf(Math.max(...dataValues));

    // Define colors: Highlight the bar with the maximum value
    const backgroundColors = dataValues.map((_, index) =>
      index === maxIndex ? "#008578" : "rgba(200, 200, 200, 0.5)"
    );

    const data = {
      labels: labels,
      datasets: [
        {
          label: "Attendance (%)",
          data: dataValues,
          backgroundColor: backgroundColors,
          borderRadius: 10,
          barThickness: 40,
        },
      ],
    };

    const options = {
      indexAxis: "x",
      scales: {
        y: {
          beginAtZero: true,
          max: 100,
          ticks: {
            callback: function (value) {
              return `${value}%`;
            },
            font: {
              size: 12,
            },
          },
          grid: {
            color: "#E0E0E0",
          },
        },
        x: {
            ticks: {
                callback: function (value, index) {
                    return labels[index]?.toUpperCase();
                },
                font: {
                    size: 12,
                },
                color: '#141517',
                padding: 10,
                maxRotation: 45,
                minRotation: 45, 
            },
            grid: {
                display: false,
            },
        },
    },
      plugins: {
        legend: {
            display: false, 
        },
        tooltip: {
            enabled: true,
            callbacks: {
                label: function (context) {
                    return `${context.raw}%`; 
                },
            },
        },
        datalabels: {
          display: true,
          color: "black",
          anchor: "end",
          align: "start",
          formatter: (value) => `${value.toFixed(0)}%`,
          font: {
            weight: "bold",
            size: 14,
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
    };

    setChartData(data);
    setChartOptions(options);
  }, [departmentData]);

  return (
    <div>
      <Chart type="bar" data={chartData} options={chartOptions} />
    </div>
  );
}
