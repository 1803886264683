import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { useNavigate, useLocation } from "react-router-dom";
import editIcon from "../../../../../assets/assests/edit.png";
import eyeIcon from "../../../../../assets/assests/eye.png";
import { Dialog } from "primereact/dialog";
import { Menu } from "primereact/menu";
import { TabView, TabPanel } from "primereact/tabview";
import QualificationForm from "./qualification-form";
import CountriesForm from "./countries-form";
import ServicesForm from "./services-form";
import TrainingForm from "./training-form";
import config from "../../../../../config";
import {
  employeeColumns,
  qualificationColumns,
  countriesColumns,
  servicesColumns,
  trainingColumns,
  attendanceColumns,
  actionTemplate,
} from "./columns-data";

export default function EmployeeDetails() {
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const location = useLocation();

  const employee = location.state?.employee;
  const employees = employee ? [employee] : [];

  console.log("employees==", employee);

  const [globalFilter, setGlobalFilter] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogTitle, setDialogTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [employeeQualifications, setEmployeeQualifications] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  // const [qualifications, setQualifications] = useState(dummyQualifications);
  const [countries, setCountries] = useState([]);
  const [employeeServices, setEmployeeServices] = useState([]);
  const [employeeCountries, setEmployeeCountries] = useState([]);
  const [employeeTrainings, setEmployeeTrainings] = useState([]);
  const [services, setServices] = useState([]);
  const [training, setTraining] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [attendanceDetails, setAttendanceDetails] = useState([]);
  const [leaveDetailsList, setLeaveDetailsList] = useState([]);

  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const handleBackClick = () => {
    navigate("/employee");
  };

  const onHide = () => {
    setShowDialog(false);
    setDialogContent(null);
  };

  const [depList, setDepartments] = useState([]);

  const token = localStorage.getItem("authToken");
  const GetDepartment = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await fetch(`${config.baseUrl}api/Department/GetAll`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      if (result && result.data) {
        const filtered = result.data.map((department) => ({
          label: department.name, // Dropdown label
          value: department.id, // Dropdown value (id of the department)
        }));
        setDepartments(filtered); // Set the formatted data for dropdown
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const GetEmployeeQualifications = async (filters = {}) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);
      const response = await fetch(
        `${config.baseUrl}api/EmployeeQualifications/GetAllQualifications/${employee?.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setEmployeeQualifications(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  const GetEmployeeCountries = async (filters = {}) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);
      const response = await fetch(
        `${config.baseUrl}api/EmployeeTrips/GetAllTrips/${employee?.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setEmployeeCountries(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  const GetEmployeeServices = async (filters = {}) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);
      const response = await fetch(
        `${config.baseUrl}api/EmployeeServices/GetAllServices/${employee?.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setEmployeeServices(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  const GetEmployeeTrainings = async (filters = {}) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);
      const response = await fetch(
        `${config.baseUrl}api/EmployeeTrainings/GetAllTrainings/${employee?.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setEmployeeTrainings(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  const GetEmployeeAttendance = async (filters = {}) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);
      const response = await fetch(
        `${config.baseUrl}api/EmployeeAttendance/GetAttendance/${employee?.id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log(data);
      setAttendanceDetails(data.attendanceDetails); // Assuming data structure
      setLeaveDetailsList(data.leavesDetail); // Set leave details

      setLoading(false);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetDepartment();
    GetEmployeeQualifications();
    GetEmployeeServices();
    GetEmployeeCountries();
    GetEmployeeTrainings();
    GetEmployeeAttendance();
  }, []);

  const openModal = (
    actionType,
    rowData = null,
    dataType,
    isEdit = false,
    isView = false
  ) => {
    const formMapping = {
      qualification: {
        component: QualificationForm,
        state: employeeQualifications,
        setState: setEmployeeQualifications,
        index: 1,
      },
      countries: {
        component: CountriesForm,
        state: employeeCountries,
        setState: setEmployeeCountries,
        index: 2,
      },
      services: {
        component: ServicesForm,
        state: employeeServices,
        setState: setEmployeeServices,
        index: 3,
      },
      training: {
        component: TrainingForm,
        state: employeeTrainings,
        setState: setEmployeeTrainings,
        index: 4,
      },
      attendance: {
        state: attendanceDetails,
        setState: setAttendanceDetails,
        index: 5,
      },
    };

    const {
      component: FormComponent,
      state,
      setState,
      index,
    } = formMapping[dataType];

    const handleSave = (newData) => {
      setActiveIndex(index);
    };

    setDialogContent(
      <FormComponent
        {...{ [dataType]: rowData }}
        initialData={rowData}
        onHide={onHide}
        onSave={handleSave}
        GetEmployeeQualifications={GetEmployeeQualifications}
        GetEmployeeServices={GetEmployeeServices}
        GetEmployeeCountries={GetEmployeeCountries}
        GetEmployeeTrainings={GetEmployeeTrainings}
        GetEmployeeAttendance={GetEmployeeAttendance}
        isView={isView}
        isEdit={isEdit}
        employee={employee?.id}
      />
    );
    setDialogTitle(`${actionType} ${dataType}`);
    setShowDialog(true);
  };

  const items = [
    {
      label: "Add Qualification",
      command: () => openModal("Add", null, "qualification"),
    },
    {
      label: "Add Countries",
      command: () => openModal("Add", null, "countries"),
    },
    {
      label: "Add Services",
      command: () => openModal("Add", null, "services"),
    },
    {
      label: "Add Training",
      command: () => openModal("Add", null, "training"),
    },
  ];

  const renderDialogContent = () => dialogContent;

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">{dialogTitle}</div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        {renderDialogContent()}
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-6">
          <h5 className="pages-internal-heading">
            <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
              Employee Management
            </span>{" "}
            &gt; Employee Details
          </h5>
        </div>

        <div className="col-12 md:col-6 justify-content-end filter-responsive">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
          <Menu model={items} popup ref={menuRef} />

          <Button
            className="p-button ml-3 add-new-dropdown"
            onClick={(event) => {
              menuRef.current.toggle(event);
            }}
          >
            <i className="pi pi-plus" />
            Add New
            <i className="pi pi-chevron-down" />
          </Button>
        </div>
      </div>

      <div className="card">
        <TabView
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
        >
          <TabPanel header="Employee Details">
            <DataTable
              emptyMessage="No record found."
              value={employees}
              className="custom-data-table"
              globalFilter={globalFilter}
            >
              {employeeColumns.map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={data.body}
                />
              ))}
            </DataTable>
          </TabPanel>
          <TabPanel header="Qualification">
            <DataTable
              emptyMessage="No record found."
              value={employeeQualifications}
              globalFilter={globalFilter}
              className="custom-data-table"
              paginator
              rows={10}
              paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            >
              {qualificationColumns.map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={
                    data.body
                      ? (rowData) =>
                          actionTemplate(
                            rowData,
                            openModal,
                            "qualification",
                            setIsEdit,
                            setIsView
                          )
                      : null
                  }
                />
              ))}
            </DataTable>
          </TabPanel>
          <TabPanel header="Countries">
            <DataTable
              emptyMessage="No record found."
              value={employeeCountries}
              globalFilter={globalFilter}
              className="custom-data-table"
              paginator
              rows={10}
              paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            >
              {countriesColumns.map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={
                    data.header === "Action"
                      ? (rowData) =>
                          actionTemplate(
                            rowData,
                            openModal,
                            "countries",
                            setIsEdit,
                            setIsView
                          )
                      : data.body
                  }
                />
              ))}
            </DataTable>
          </TabPanel>
          <TabPanel header="Services">
            <DataTable
              emptyMessage="No record found."
              value={employeeServices}
              className="custom-data-table"
              paginator
              rows={10}
              paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            >
              {servicesColumns(depList).map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={
                    data.header === "Action"
                      ? (rowData) =>
                          actionTemplate(
                            rowData,
                            openModal,
                            "services",
                            setIsEdit,
                            setIsView
                          )
                      : data.body
                  }
                />
              ))}
            </DataTable>
          </TabPanel>
          <TabPanel header="Trainings">
            <DataTable
              emptyMessage="No record found."
              value={employeeTrainings}
              className="custom-data-table"
              paginator
              rows={10}
              paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            >
              {trainingColumns.map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={
                    data.header === "Action"
                      ? (rowData) =>
                          actionTemplate(
                            rowData,
                            openModal,
                            "training",
                            setIsEdit,
                            setIsView
                          )
                      : data.body
                  }
                />
              ))}
            </DataTable>
          </TabPanel>
          <TabPanel header="Attendance">
            <DataTable
              emptyMessage="No record found."
              value={attendanceDetails}
              className="custom-data-table"
              paginator
              rows={4}
              paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            >
              {attendanceColumns.map((data, index) => (
                <Column
                  key={index}
                  field={data.field}
                  header={data.header}
                  body={data.body}
                />
              ))}
            </DataTable>
            <br/>
            
            <div className="leave-grid">
              {leaveDetailsList?.map((leave, index) => (
                <>
                  <div key={index} className="leave-card">
                    <div className="leave-header">
                      {leave?.leaveTypeName} Leaves
                    </div>
                    <div className="leave-body">
                      <div className="leave-row">
                        <span>Leave Type</span>
                        <span className="leave-value">
                          {leave?.leaveTypeName} Leaves
                        </span>
                      </div>
                      <div className="leave-row">
                        <span>Total Available</span>
                        <span className="leave-value">
                          {leave?.totalAvailable}
                        </span>
                      </div>
                      <div className="leave-row">
                        <span>Remaining</span>
                        <span className="leave-value">{leave?.remaining}</span>
                      </div>
                      <div className="leave-row">
                        <span>Total Availed</span>
                        <span className="leave-value">
                          {leave?.totalAvailed}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}
