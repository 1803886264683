import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";

export default function BarChartRegion({ regionData }) {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    // Prepare data for the chart
    const labels = regionData.map(region => region.regionName);
    const presentCounts = regionData.map(region => region.presentCount);
    const leaveCounts = regionData.map(region => region.leaveCount);
    const absentCounts = regionData.map(region => region.absentCount);
    const lateArrivalCounts = regionData.map(region => region.lateArrivalCount);
    const earlyArrivalCounts = regionData.map(region => region.earlyArrivalCount);

    setChartData({
      labels,
      datasets: [
        {
          label: 'Present Count',
          backgroundColor: '#66BB6A',
          data: presentCounts,
        },
        {
          label: 'Leave Count',
          backgroundColor: '#42A5F5',
          data: leaveCounts,
        },
        {
          label: 'Absent Count',
          backgroundColor: '#EF5350',
          data: absentCounts,
        },
        {
          label: 'Late Arrival Count',
          backgroundColor: '#FFA726',
          data: lateArrivalCounts,
        },
        {
          label: 'Early Arrival Count',
          backgroundColor: '#AB47BC',
          data: earlyArrivalCounts,
        },
      ],
    });

    setChartOptions({
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
          min: 0,
          max: 60,
          ticks: {
            stepSize: 10,
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom",
        },
      },
    });
  }, [regionData]);


  return (
    <div>
      <Chart type="bar" data={chartData} options={chartOptions} />
    </div>
  );
}

