import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import editIcon from "../../../../assets/assests/edit.png";
import eyeIcon from "../../../../assets/assests/eye.png";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import EditAddForm from "./edit-add-form";
import config from "../../../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tag } from "primereact/tag";
import Spinner from "../../components/spinner";
import { Dropdown } from "primereact/dropdown";
import usePermissions from "../../components/hooks/usePermissions";

export default function LeaveScreen() {
  const navigate = useNavigate();

  const [globalFilter, setGlobalFilter] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [res, setRes] = useState([]);
  const [rowselect, setRowselect] = useState(null);
  const [editable, setEditable] = useState();
  const [loading, setLoading] = useState(false);
  const [leaveList, setLeaveList] = useState([]);
  const [filterSelectedStatus, setFilterSelectedStatus] = useState(null);
  const [filteredLeaveList, setFilteredLeaveList] = useState([]); 

  const permissions = usePermissions("Leave");

  const GetLeaveData = async (filters = {}) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);
      const response = await fetch(
        `${config.baseUrl}api/Leaves/GetAllLeaveRequests`,
        {
          method: "Get",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();
      setLeaveList(data.data);
      setFilteredLeaveList(data.data); 

      setLoading(false);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetLeaveData();
  }, []);

  useEffect(() => {
    let tempList = leaveList;

    if (filterSelectedStatus) {
      tempList = tempList.filter(
        (leave) => leave.status === filterSelectedStatus
      );
    }

    if (globalFilter) {
      tempList = tempList.filter(
        (leave) =>
          leave.name.toLowerCase().includes(globalFilter.toLowerCase()) ||
          leave.employeeNumber.toString().includes(globalFilter)
      );
    }

    setFilteredLeaveList(tempList);
  }, [filterSelectedStatus, globalFilter, leaveList]);

  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setShowDialog(true);
  };

  const handleGlobalFilterChange = (e) => {
    setGlobalFilter(e.target.value);
  };

  const onHide = () => {
    setShowDialog(false);
  };

  const toggleDialogMode = (editMode = false) => {
    setShowDialog(true);
    setIsEdit(editMode);
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="action-buttons flex">
        {permissions?.view && (
          <Button
            className="p-button-rounded p-button-edit p-0 mr-3 action-images"
            onClick={() => {
              navigate(`/leave/details/${rowData.id}`, {
                state: { employee: rowData },
              });
            }}
            aria-label="view"
          >
            <img src={eyeIcon} alt="Eye" />
          </Button>
        )}
        {permissions?.edit && (
          <Button
            className=" p-button-edit p-0 mr-3"
            onClick={() => {
              toggleDialogMode(true);
              editAction(rowData);
            }}
            aria-label="Edit"
          >
            <img src={editIcon} alt="Edit" />
          </Button>
        )}
      </div>
    );
  };

  const handleDetailsClick = (rowData) => {
    navigate(`/leave/details/${rowData.employeeId}`, {
      state: { employee: rowData },
    });
  };
  const rowClassName = "p-datatable-clickable";

  const columnsData = [
    {
      field: "employeeNumber",
      header: "Emp No",
    },
    {
      field: "name",
      header: "Name",
    },

    {
      field: "leaveType",
      header: "Leave Type",
    },
    {
      field: "from",
      header: "From",
    },
    {
      field: "to",
      header: "To",
    },
    {
      field: "reason",
      header: "Reason",
    },
    {
      field: "status",
      header: "STATUS",
      body: (rowData) => {
        const status = rowData.status;
        return status === "Approved" ? (
          <Tag className="custom-success-tag" value="Approved" />
        ) : (
          <Tag className="custom-info-tag" value="Pending" />
        );
      },
    },
    // {
    //   header: "Action",
    //   body: actionTemplate,
    // },
    ...(permissions?.view ? [{ header: "Action", body: actionTemplate }] : []),
  ];

  const handleStatusChange = (e) => {
    const selectedStatus = e.value !== undefined ? e.value : null;
    setFilterSelectedStatus(selectedStatus);
  };

  const filterStatusValue = [
    { label: "Pending", value: "Pending" },
    { label: "Approved", value: "Approved" },
  ];

  return (
    <>
      <ToastContainer />

      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">
              {isEdit ? "Edit Leave" : "Add Leave"}
            </div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        <EditAddForm
          rowData={rowselect}
          setShowDialog={setShowDialog}
          GetLeaveData={GetLeaveData}
          showDialog={showDialog}
          editable={editable}
          onHide={onHide}
          formData={isEdit ? selectedData : null}
          setRes={setRes}
        />
      </Dialog>
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-6">
          <h5 className="pages-internal-heading">Leave Management</h5>
        </div>

        <div className="col-12 md:col-6 justify-content-end filter-responsive">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>

          <Dropdown
            className="status__dropdown dropdown-size mt-0 mb-0 ml-3"
            value={filterSelectedStatus}
            onChange={handleStatusChange}
            optionValue="value"
            options={filterStatusValue}
            optionLabel="label"
            placeholder="Select Status"
            showClear
          />
          {permissions?.add && (
            <Button
              label="Add Leave"
              icon="pi pi-plus"
              onClick={() => {
                setEditable(false);
                setShowDialog(true);
              }}
              className="p-button ml-3 mt-2"
            />
          )}
        </div>
      </div>
      {loading && (
        <div className="spinner-overlay">
          <Spinner />
        </div>
      )}
      <div className="card">
        <DataTable
          emptyMessage="No record found."
          value={filteredLeaveList}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          globalFilter={globalFilter}
        >
          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
}
