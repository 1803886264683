import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import ActionButtons from "../../components/action_buttons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import config from "../../../../config";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../components/spinner";

const EditAddForm = ({
  onHide,
  editable,
  setRes,
  setShowDialog,
  GetEmployeeAttendance,
}) => {
  const [loading, setLoading] = useState(false);
  const [apiRes, setApiRes] = useState();
  const [employeeList, setEmployeeList] = useState([]);

  const validationSchema = Yup.object({
    employeeName: Yup.string().required("Required"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      employeeName: "",
    },
    onSubmit: async (data) => {
      const token = localStorage.getItem("authToken");
      setLoading(true);
      try {
        const response = await axios.post(
          `${config.baseUrl}api/Attendance/AddAttendance/${data.employeeName}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        setApiRes(response);
        setRes(response);
        toast.success("Employee attendance added successfully!");
        GetEmployeeAttendance();
        setShowDialog(false);
      } catch (error) {
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    },
  });

  const GetEmployee = async (filters = {}) => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);
      const response = await fetch(
        `${config.baseUrl}api/Employee/GetEmployeesNameAndId`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
  
      const result = await response.json();
      const employees = result?.data?.map((employee) => ({
        label: employee?.name,
        value: employee?.id,
      }));
      
      // console.log("employees:", employees); // Log to check if data is mapped correctly
      setEmployeeList(employees);
    } catch (error) {
      console.error("Error fetching employee data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetEmployee();
  }, []);


  return (
    <>
      <ToastContainer />
      <div className="main-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-12">
              <label htmlFor="employeeName">
                Employee<span className="Staric-Custom text-danger"> *</span>
              </label>
              <Dropdown
                id="employeeName"
                name="employeeName"
                value={formik.values.employeeName}
                options={employeeList}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Select Employee"
              />
              {formik.touched.employeeName && formik.errors.employeeName && (
                <small className="p-error">{formik.errors.employeeName}</small>
              )}
            </div>
            {loading && (
              <div className="spinner-overlay">
                <Spinner />
              </div>
            )}
          </div>

          <div className="p mt-4 form-buttons">
          <ActionButtons
             loading={loading} 
             onCancel={onHide}
             onSave={formik.handleSubmit}
             saveLabel={editable ? "Update Changes" : "Save Changes"}
             showSave={true}
             cancelLabel="Cancel"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default EditAddForm;
