import React, { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import config from "../../../../config";
import Spinner from "../../components/spinner";

export default function ViewAttendanceScreen({ globalFilter, dateRange }) {
  const [loading, setLoading] = useState(false);
  const [attendance, setAttendance] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(
    dateRange || [
      new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      new Date(),
    ] // Default to the first day of the current month
  );

  const selectedDate = dateRange[0]; // Start date
  const year = selectedDate.getFullYear();
  const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
  const date = String(selectedDate.getDate()).padStart(2, "0");

  // Format date as "YYYY-MM-DD"
  const formattedDate = `${year}-${month}-${date}`;

  const GetEmployeeAttendanceDetails = async () => {
    const token = localStorage.getItem("authToken");
    const requestBody = { date: formattedDate };
    setLoading(true);

    try {
      const response = await fetch(
        `${config.baseUrl}api/Attendance/GetMonthlyAttendance`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (data.status === 200) {
        setAttendance(data.data);
      }
    } catch (error) {
      console.error("Error fetching department attendance:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetEmployeeAttendanceDetails();
  }, [dateRange]);

  const transformAttendanceData = () => {
    const totalDays = 30; // Assuming the month has 30 days
    return attendance
      .filter((employee) =>
        employee.employeeName.toLowerCase().includes(globalFilter.toLowerCase())
      )
      .map((employee) => {
        const transformedData = { employeeName: employee.employeeName };
        employee.attendanceStatus.forEach((status, index) => {
          transformedData[`day${index + 1}`] = status;
        });
        // Fill in future days with '--'
        for (
          let day = employee.attendanceStatus.length + 1;
          day <= totalDays;
          day++
        ) {
          transformedData[`day${day}`] = "--"; // Show '--' for future days
        }
        return transformedData;
      });
  };

  const generateColumns = () => {
    let columns = [{ field: "employeeName", header: "EMPLOYEE NAME" }];
    for (let day = 1; day <= 30; day++) {
      columns.push({
        field: `day${day}`,
        header: `${day}`,
        body: (rowData) => (
          <span>
            {rowData[`day${day}`] === "Present" ? (
              <span style={{ color: "green", fontSize: "20px" }}>✓</span>
            ) : rowData[`day${day}`] === "Leave" ? (
              <span style={{ color: "#F59E0B", fontWeight: "bold" }}>L</span>
            ) : rowData[`day${day}`] === "Absent" ? (
              <span style={{ color: "red", fontSize: "20px" }}>✗</span>
            ) : (
              <span style={{ color: "gray", fontSize: "20px" }}>--</span>
            )}
          </span>
        ),
      });
    }
    return columns;
  };

  return (
    <>
      {loading && (
        <div className="spinner-overlay">
          <Spinner />
        </div>
      )}

      <DataTable
        value={transformAttendanceData()}
        emptyMessage="No record found."
        className="custom-data-table"
        scrollable
        paginator
        rows={10}
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
      >
        {generateColumns().map((data, index) => (
          <Column
            key={index}
            field={data.field}
            header={data.header}
            body={data.body}
          />
        ))}
      </DataTable>
    </>
  );
}
