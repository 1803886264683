import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ActionButtons from "../../../components/action_buttons";
import config from "../../../../../config";
import { toast } from "react-toastify";

const ServicesForm = ({
  onHide,
  formData,
  onSave,
  isEdit,
  isView,
  initialData,
  GetEmployeeServices,
  employee,
}) => {
  const [loading, setLoading] = useState(false);

  const yearOptions = [
    { label: "2005", value: "2005" },
    { label: "2006", value: "2006" },
    { label: "2007", value: "2007" },
    { label: "2008", value: "2008" },
    // Add more year options as needed
  ];
  const [depList, setDepartments] = useState([]);

  const token = localStorage.getItem("authToken");
  const GetDepartment = async () => {
    const token = localStorage.getItem("authToken");
    try {
      setLoading(true);

      const response = await fetch(`${config.baseUrl}api/Department/GetAll`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const result = await response.json();
      if (result && result.data) {
        const filtered = result.data.map((department) => ({
          label: department.name, // Dropdown label
          value: department.id, // Dropdown value (id of the department)
        }));
        setDepartments(filtered); // Set the formatted data for dropdown
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  useEffect(() => {
    GetDepartment();
  }, []);

  const formik = useFormik({
    initialValues: {
      serviceYear: initialData?.serviceYear || "",
      postHeldAndGrade: initialData?.postHeldAndGrade || "",
      departmentId: initialData?.departmentId || "",
      station: initialData?.station || "",
      from: initialData?.from ? new Date(initialData.from) : null,
      to: initialData?.to ? new Date(initialData.to) : null,
    },
    validationSchema: Yup.object({
      serviceYear: Yup.string().required("Service Year is required"),
      postHeldAndGrade: Yup.string().required(
        "Post Held And Grade is required"
      ),
      departmentId: Yup.number().required("Department is required"),
      station: Yup.string().required("Station is required"),
      to: Yup.date()
        .typeError("Please enter a valid date")
        .required("End Date is required")
        .min(Yup.ref("from"), "End date cannot be before start date"),

      from: Yup.date()
        .typeError("Please enter a valid date")
        .required("Start date is required"),
    }),
    onSubmit: async (values) => {
      const payload = {
        ...values,
        from: values.from ? values.from.toISOString() : "",
        to: values.to ? values.to.toISOString() : "",
      };

      try {
        setLoading(true);

        if (isEdit) {

          payload.Id = initialData?.id;
          await fetch(`${config.baseUrl}api/EmployeeServices/UpdateService`, {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          });
          toast.success("Updated successfully");
        } else {
          payload.employeeId = employee;

          await fetch(`${config.baseUrl}api/EmployeeServices/AddService`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          });
          toast.success("Added successfully");
        }
        setLoading(false);
        GetEmployeeServices(); // Refresh service list after saving
        onSave(); // Handle successful save
      } catch (error) {
        console.error("Error while submitting service:", error);
      } finally {
        onHide(); // Close modal
        setLoading(false);
      }
    },
  });

  return (
    <div className="main-form">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
        <div className="field col-12 md:col-4">
        <label htmlFor="serviceYear">Service Year</label>
            <Dropdown
              id="serviceYear"
              options={yearOptions}
              placeholder="Select Year"
              value={formik.values.serviceYear}
              onChange={(e) => formik.setFieldValue("serviceYear", e.value)}
              disabled={isView}
            />
            {formik.touched.serviceYear && formik.errors.serviceYear ? (
              <small className="p-error">{formik.errors.serviceYear}</small>
            ) : null}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="postHeldAndGrade">Post Held & Grade</label>
            <InputText
              id="postHeldAndGrade"
              placeholder="Enter Post Held & Grade"
              value={formik.values.postHeldAndGrade}
              onChange={formik.handleChange}
              disabled={isView}
            />
            {formik.touched.postHeldAndGrade &&
            formik.errors.postHeldAndGrade ? (
              <small className="p-error">
                {formik.errors.postHeldAndGrade}
              </small>
            ) : null}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="departmentId">Department</label>
            <Dropdown
              id="departmentId"
              options={depList} // List of departments with label and value
              placeholder="Select Department"
              value={formik.values.departmentId} // Bind to Formik's value
              onChange={(e) => formik.setFieldValue("departmentId", e.value)} // Update Formik value
              disabled={isView} // Disable if view mode
            />
            {formik.touched.departmentId && formik.errors.departmentId ? (
              <small className="p-error">{formik.errors.departmentId}</small>
            ) : null}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="station">Station</label>
            <InputText
              id="station"
              placeholder="Enter Station"
              value={formik.values.station}
              onChange={formik.handleChange}
              disabled={isView}
            />
            {formik.touched.station && formik.errors.station ? (
              <small className="p-error">{formik.errors.station}</small>
            ) : null}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="from">From</label>
            <Calendar
              id="from"
              value={formik.values.from}
              onChange={(e) => formik.setFieldValue("from", e.value)}
              showIcon
              disabled={isView}
              placeholder="Enter"
            />
            {formik.touched.from && formik.errors.from ? (
              <small className="p-error">{formik.errors.from}</small>
            ) : null}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="to">To</label>
            <Calendar
              id="to"
              value={formik.values.to}
              onChange={(e) => formik.setFieldValue("to", e.value)}
              showIcon
              disabled={isView}
              placeholder="Enter"
            />
            {formik.touched.to && formik.errors.to ? (
              <small className="p-error">{formik.errors.to}</small>
            ) : null}
          </div>
        </div>

        <div className="p mt-4 form-buttons">
          <ActionButtons
             loading={loading}
            onCancel={onHide}
            onSave={formik.handleSubmit}
            saveLabel={isEdit ? "Update Changes" : "Save Changes"}
            showSave={!isView}
            cancelLabel="Cancel"
          />
        </div>
      </form>
    </div>
  );
};

export default ServicesForm;
