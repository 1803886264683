// hooks/usePermissions.js
import { useEffect, useState } from 'react';

const usePermissions = (moduleName) => {
  const [permissions, setPermissions] = useState({});
  
  useEffect(() => {
    const storedPermissions = localStorage.getItem("userPermissions");
    if (storedPermissions) {
      const parsedPermissions = JSON.parse(storedPermissions);
      const modulePermission = parsedPermissions.find(p => p.moduleName === moduleName);
      setPermissions(modulePermission || {});
    }
  }, [moduleName]);

  return permissions;
};

export default usePermissions;
