// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

// Fallback component
export const NotPermitted = () => (
  <div className="not-permitted">
    <h2>Not Permitted</h2>
    <p>You do not have permission to access this page.</p>
  </div>
);

// const getUserPermissions = () => {
//   const permissions = localStorage.getItem("permissions");
//   return permissions ? JSON.parse(permissions) : [];
// };

// const permissions= [
//     { 
//         moduleId: 1,
//         module: "Dashboard",
//         view: true,
//         edit: true,
//         add: false,
//         delete: true
//     },
//     {
//       moduleId: 2,
//       module: "Employee",
//       view: true,
//       edit: true,
//       add: false,
//       delete: false
//       },
//       {
//         moduleId: 3,
//         module: "Attendance",
//         view: true,
//         edit: true,
//         add: false,
//         delete: false
//         } ,
//         {
//           moduleId: 4,
//           module: "Leave",
//           view: true,
//           edit: true,
//           add: false,
//           delete: false
//           }  ,
//           {
//             moduleId: 5,
//             module: "Shift",
//             view: true,
//             edit: true,
//             add: false,
//             delete: false
//             }  ,
//             {
//               moduleId: 6,
//               module: "Settings",
//               view: true,
//               edit: true,
//               add: false,
//               delete: false
//               } ,
//               {
//                 moduleId: 7,
//                 module: "Roles & Rights",
//                 view: true,
//                 edit: true,
//                 add: false,
//                 delete: false
//                 } ,
//                 {
//                   moduleId: 7,
//                   module: "NOC Approval",
//                   view: true,
//                   edit: true,
//                   add: false,
//                   delete: false
//                   } 
//   ]

// const storedPermissions = localStorage.getItem("userPermissions");
// const permissions = storedPermissions ? JSON.parse(storedPermissions) : [];

// console.log("permissions", permissions)


const ProtectedRoute = ({ element: Component, permission, fallback = <NotPermitted />, permissions }) => {

    const hasPermission = permissions?.some(
      (p) => p?.moduleName === permission && p.view === true
    );
    return hasPermission ? <Component /> : fallback;
  };
  
  export default ProtectedRoute;
  
