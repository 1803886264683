import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Dialog } from "primereact/dialog";
import logo from "../../../../assets/assests/logo.png";
import google from "../../../../assets/assests/google.png";
import axios from "axios";
import { toast } from "react-toastify";
import config from "../../../../config";
import OtpScreen from "./otpscreen";
import ActionButtons from "../../components/action_buttons";

export default function LoginScreen({ handleLogin }) {
  const [showDialog, setShowDialog] = useState(false);
  const [userName, setUserName] = useState("");
  const [otpFromLogin, setOtpFromLogin] = useState(null);
  const [otpExpiryFromLogin, setOtpExpiryFromLogin] = useState(null);
  const [forgotPassDialog, setForgotPassDialog] = useState(false);
  const [resetPassDialog, setResetPassDialog] = useState(false);
  const [emailStored, setEmailStored] = useState("");
  const [resetToken, setResetToken] = useState("");

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      userName: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Password is required."),
      userName: Yup.string().required("User Name is required."),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${config.baseUrl}api/Account/PortalLogin`,
          values,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.data?.data?.otp) {
          setUserName(values.userName);
          setOtpFromLogin(response?.data?.data.otp);
          setOtpExpiryFromLogin(response?.data?.data.otpExpiry);
          setShowDialog(true);
          toast.success("Please enter OTP.");
        } else {
          toast.error(response.data.message || "Login failed");
        }
      } catch (error) {
        console.error("Login error:", error);
        toast.error(
          error.response?.data?.message ||
            "Login failed due to a network error. Please try again."
        );
      } finally {
        setLoading(false);
      }
    },
  });

  const onHide = () => {
    setShowDialog(false);
  };

  const passForgotFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const response = await axios.post(
          `${config.baseUrl}api/Account/forget-password`,
          {
            Email: values.email,
          }
        );
        if (response.status === 200 && response.data?.data) {
          toast.success("Password reset link sent to your email!");

          setEmailStored(values.email);
          setResetToken(response.data.data?.passwordResetToken);

          resetForm();
          setForgotPassDialog(false);
          setResetPassDialog(true); 
        } else {
          const errorMessage =
            response.data?.message || "An unexpected error occurred.";
          toast.error(
            `${errorMessage} email entered. Please try another email address associated with this account.`
          );
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || error.message || "An error occurred";
        toast.error(
          `Error: ${errorMessage} email entered. Please try another email address associated with this account.`
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  const passResetFormik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")

        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character"
        ),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const response = await axios.post(
          `${config.baseUrl}api/Account/reset-password`,
          {
            Email: emailStored,
            Token: resetToken, // Send the stored token
            NewPassword: values.password,
          }
        );
        if (response.status === 200) {
          toast.success("Password reset successfully!");
          resetForm();
          setResetPassDialog(false);
        } else {
          const errorMessage =
            response.data?.message || "An unexpected error occurred.";
          toast.error(errorMessage);
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || error.message || "An error occurred";
        toast.error(`Error: ${errorMessage}`);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handlePassDialogHide = () => {
    passForgotFormik.resetForm();
    setForgotPassDialog(false);
  };

  const handleResetDialogHide = () => {
    passResetFormik.resetForm();
    setResetPassDialog(false);
  };

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">OTP VERIFICATION</div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        <OtpScreen
          userName={userName}
          onHide={onHide}
          otpFromLogin={otpFromLogin}
          otpExpiryFromLogin={otpExpiryFromLogin}
          loading={loading}
          handleLogin={handleLogin}
        />
      </Dialog>

      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">Forgot Password</div>
          </div>
        }
        visible={forgotPassDialog}
        onHide={handlePassDialogHide}
        className="dialog-size"
      >
        <form onSubmit={passForgotFormik.handleSubmit}>
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-12">
              <label htmlFor="email">
                Email <span className="Staric-Custom text-danger"> *</span>
              </label>
              <InputText
                type="email"
                placeholder="Enter Your Email here"
                id="email"
                name="email"
                value={passForgotFormik.values.email}
                onChange={passForgotFormik.handleChange}
                onBlur={passForgotFormik.handleBlur}
              />
              {passForgotFormik.touched.email &&
              passForgotFormik.errors.email ? (
                <div className="error text-danger">
                  {passForgotFormik.errors.email}
                </div>
              ) : null}
            </div>
          </div>
          <div className="p mt-4 form-buttons">
            <ActionButtons
              loading={passForgotFormik.isSubmitting}
              onCancel={handlePassDialogHide}
              onSave={passForgotFormik.handleSubmit}
              saveLabel={"Submit"}
              showSave={true}
              cancelLabel="Cancel"
            />
          </div>
        </form>
      </Dialog>

      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">Reset Password</div>
          </div>
        }
        visible={resetPassDialog}
        onHide={handleResetDialogHide}
        className="dialog-size"
      >
        <form onSubmit={passResetFormik.handleSubmit}>
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-12">
              <label htmlFor="email">
                Email <span className="Staric-Custom text-danger"> *</span>
              </label>
              {/* Prefill email from state */}
              <InputText
                type="email"
                id="email"
                name="email"
                value={emailStored}
                readOnly
              />
            </div>
            <div className="field col-12 md:col-12 pass-pass">
              <label htmlFor="password">
                Password <span className="Staric-Custom text-danger"> *</span>
              </label>
              <Password
                toggleMask
                feedback={false}
                id="password"
                maxLength={8}
                name="password"
                value={passResetFormik.values.password}
                onChange={passResetFormik.handleChange}
                placeholder="Enter Password"
              />
              {passResetFormik.errors.password &&
                passResetFormik.touched.password && (
                  <small className="p-error">
                    {passResetFormik.errors.password}
                  </small>
                )}
            </div>
          </div>
          <div className="p mt-4 form-buttons">
            <ActionButtons
              loading={passResetFormik.isSubmitting}
              onCancel={handleResetDialogHide}
              onSave={passResetFormik.handleSubmit}
              saveLabel={"Submit"}
              showSave={true}
              cancelLabel="Cancel"
            />
          </div>
        </form>
      </Dialog>

      <div className="auth-page">
        <div className="container">
          <div className="content-wrapper">
            <div className="sign-in-box">
              <h2>Sign in</h2>
              <p className="create-account">
                {/* New user? <a href="#">Create an account</a> */}
              </p>
              <form onSubmit={formik.handleSubmit}>
                <div className="input-group">
                  <FloatLabel>
                    <InputText
                      id="email"
                      name="userName"
                      value={formik.values.userName}
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="email">User Name</label>
                  </FloatLabel>
                  {formik.touched.userName && formik.errors.userName ? (
                    <div className="error">{formik.errors.userName}</div>
                  ) : null}
                </div>
                <div className="input-group">
                  <FloatLabel>
                    <Password
                      feedback={false}
                      inputId="password"
                      id="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      toggleMask
                    />
                    <label htmlFor="password">Password</label>
                  </FloatLabel>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="error">{formik.errors.password}</div>
                  ) : null}
                </div>

                <Button
                  type="submit"
                  className="p-button sign-in-button"
                  loading={loading}
                  disabled={loading}
                  label={"Sign in"}
                />
                <div className="link-a">
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setForgotPassDialog(true);
                    }}
                  >
                    Forgot password?
                  </a>{" "}
                </div>

                {/* <div className="divider">
                  <span></span>
                  <p>or</p>
                  <span></span>
                </div> */}
                {/* <button type="button" className="google-sign-in">
                  <img src={google} alt="Google icon" /> Google
                </button> */}
                <p className="terms">
                  Protected by reCAPTCHA and subject to the{" "}
                  <a href="#">ET & NC Policy</a> and{" "}
                  <a href="#">Terms of Service</a>.
                </p>
              </form>
            </div>
            <div className="info-box">
              <img src={logo} alt="Zindigi logo" className="logo" />
              <h1>Excise, Taxation & Narcotics Control Department</h1>
              <p>
                The Excise, Taxation and Narcotics Control Department provides
                services for collection of various taxes and duties efficiently
                and effectively and suggests ways and means for additional
                resource mobilization in the Province.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
