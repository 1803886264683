import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ActionButtons from "../../../components/action_buttons";
import { InputTextarea } from "primereact/inputtextarea";
import config from "../../../../../config";
import { toast } from "react-toastify";

const TrainingForm = ({
  onHide,
  onSave,
  isEdit,
  isView,
  initialData,
  GetEmployeeTrainings,
  employee,
}) => {
  const [loading, setLoading] = useState(false);

  const typeOptions = [
    { label: "In Service Training", value: "In Service Training" },
  ];
  const cityOptions = [{ label: "Islamabad", value: "Islamabad" }];
  const formik = useFormik({
    initialValues: {
      trainingType: initialData?.trainingType || "",
      country: initialData?.country || "",
      institution: initialData?.institution || "",
      city: initialData?.city || "",
      startDate: initialData?.startDate
        ? new Date(initialData.startDate)
        : null,
      endDate: initialData?.endDate ? new Date(initialData.endDate) : null,
    },
    validationSchema: Yup.object({
      trainingType: Yup.string().required("Training type is required"),
      country: Yup.string().required("Country is required"),
      institution: Yup.string().required("Institution is required"),
      city: Yup.string().required("City is required"),
      endDate: Yup.date()
        .typeError("Please enter a valid date")
        .required("End Date is required")
        .min(Yup.ref("startDate"), "End date cannot be before start date"),

      startDate: Yup.date()
        .typeError("Please enter a valid date")
        .required("Start date is required"),
    }),
    onSubmit: async (values) => {
      const payload = {
        ...values,
        startDate: values.startDate ? values.startDate.toISOString() : null,
        endDate: values.endDate ? values.endDate.toISOString() : null,
      };
      const token = localStorage.getItem("authToken");
      const url = initialData?.id
        ? `${config.baseUrl}api/EmployeeTrainings/UpdateTraining`
        : `${config.baseUrl}api/EmployeeTrainings/AddTraining`;

      if (initialData?.id) {
        payload.id = initialData.id; // Add the id for update payload
      }
      if (!isEdit) {
        payload.employeeId = employee;
      }
      setLoading(true);

      try {
        const response = await fetch(url, {
          method: initialData?.id ? "PUT" : "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          if (initialData?.id) {
            toast.success("Updated successfully");
          } else {
            toast.success("Added successfully");
          }
        } else {
          toast.error("An error occurred. Please try again.");
        }
        GetEmployeeTrainings();
        onSave();
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred. Please try again.");
      } finally {
        onHide(); 
        setLoading(false);
      }
    },
  });

  return (
    <div className="main-form">
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field col-12 md:col-4">
            <label htmlFor="trainingType">Training Type</label>
            <Dropdown
              id="trainingType"
              options={typeOptions}
              placeholder="Select Training Type"
              value={formik.values.trainingType}
              onChange={(e) => formik.setFieldValue("trainingType", e.value)}
              disabled={isView}
            />
            {formik.touched.trainingType && formik.errors.trainingType ? (
              <small className="p-error">{formik.errors.trainingType}</small>
            ) : null}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="startDate">Starting Date</label>
            <Calendar
              id="startDate"
              value={formik.values.startDate}
              onChange={(e) => formik.setFieldValue("startDate", e.value)}
              showIcon
              disabled={isView}
              placeholder="Select Start Date"
            />
            {formik.touched.startDate && formik.errors.startDate ? (
              <small className="p-error">{formik.errors.startDate}</small>
            ) : null}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="endDate">Ending Date</label>
            <Calendar
              id="endDate"
              value={formik.values.endDate}
              onChange={(e) => formik.setFieldValue("endDate", e.value)}
              showIcon
              disabled={isView}
              placeholder="Select End Date"
            />
            {formik.touched.endDate && formik.errors.endDate ? (
              <small className="p-error">{formik.errors.endDate}</small>
            ) : null}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="city">City</label>
            <Dropdown
              id="city"
              options={cityOptions}
              placeholder="Select City"
              value={formik.values.city}
              onChange={(e) => formik.setFieldValue("city", e.value)}
              disabled={isView}
            />
            {formik.touched.city && formik.errors.city ? (
              <small className="p-error">{formik.errors.city}</small>
            ) : null}
          </div>

          <div className="field col-12 md:col-4">
            <label htmlFor="country">Country</label>
            <InputText
              id="country"
              placeholder="Enter Country"
              value={formik.values.country}
              onChange={formik.handleChange}
              disabled={isView}
            />
            {formik.touched.country && formik.errors.country ? (
              <small className="p-error">{formik.errors.country}</small>
            ) : null}
          </div>

          <div className="field col-12 md:col-12">
            <label htmlFor="institution">Institution</label>
            <InputTextarea
              id="institution"
              rows={4}
              cols={10}
              placeholder="Enter Institution"
              value={formik.values.institution}
              onChange={formik.handleChange}
              disabled={isView}
            />
            {formik.touched.institution && formik.errors.institution ? (
              <small className="p-error">{formik.errors.institution}</small>
            ) : null}
          </div>
        </div>

        <div className="p mt-4 form-buttons">
          <ActionButtons
            onCancel={onHide}
            loading={loading}
            onSave={formik.handleSubmit}
            saveLabel={isEdit ? "Update Changes" : "Save Changes"}
            showSave={!isView}
            cancelLabel="Cancel"
          />
        </div>
      </form>
    </div>
  );
};

export default TrainingForm;
