import { useEffect, useState } from "react";
import config from "../../../../config";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown"; // Assuming you're using PrimeReact
import { InputText } from "primereact/inputtext"; // Assuming you're using PrimeReact
import ActionButtons from "../../components/action_buttons";

const EditAddForm = ({ onHide, editable, setRes, rowData, setShowDialog }) => {
  const [apiRes, setApiRes] = useState();
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [pdfPreview, setPdfPreview] = useState(null); // New state for PDF preview
  const [isUploaded, setIsUploaded] = useState(false); // Track upload status

  const validationSchema = Yup.object({
    status: Yup.string().required("Status is required"),
    comments: Yup.string().when("status", {
      is: "3", // When status is "Reject"
      then: Yup.string().required("Comments are required for rejection"),
      otherwise: Yup.string(),
    }),
    nocFile: Yup.mixed().when("status", {
      is: "2", // When status is "Approve"
      then: Yup.mixed().required("NOC file is required"),
      otherwise: Yup.mixed(),
    }),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      status: "",
      comments: "",
      nocFile: "",
      ...(editable ? { id: rowData.id } : {}),
    },
    onSubmit: async (data) => {
      const token = localStorage.getItem("authToken");
      setLoading(true);

      const payload = {
        nocRequestId: rowData.id,
        status: data.status,
        remarks: data.comments,
        nocFile: data.nocFile,
      };

      try {
        const response = await axios.post(
          `${config.baseUrl}api/NOC/UpdateNOCRequest`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        setApiRes(response);
        toast.success("StatusUpdated Successfully");
        setRes(response);
        setShowDialog(false);
      } catch (error) {
        toast.error("Something went wrong");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleEmployeeImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      // Ensure the selected file is a PDF
      if (selectedFile.type === "application/pdf") {
        const reader = new FileReader();
        reader.onloadend = () => {
          setFileName(selectedFile);
          formik.setFieldValue("nocFile", reader.result);
          setIsUploaded(true); // Update upload status
        };
        reader.readAsDataURL(selectedFile);
      } else {
        alert("Please upload a PDF file.");
        setFileName(null);
        formik.setFieldValue("nocFile", "");
        setIsUploaded(false); // Reset upload status
      }
    } else {
      setFileName(null);
      formik.setFieldValue("nocFile", "");
      setIsUploaded(false); // Reset upload status
    }
  };

  const removeEmployeeImage = () => {
    setFileName(null);
    formik.setFieldValue("nocFile", "");
    setIsUploaded(false); // Reset upload status
  };

  const statusOptions = [
    { label: "Approve", value: "2" },
    { label: "Reject", value: "3" },
  ];

  return (
    <>
      <ToastContainer />
      <div className="main-form">
        <form onSubmit={formik.handleSubmit}>
          <div className="p-fluid formgrid grid">
            <div className="field col-12 md:col-12">
              <label htmlFor="status">
                Status <span className="Staric-Custom text-danger"> *</span>
              </label>
              <Dropdown
                id="status"
                name="status"
                value={formik.values.status}
                options={statusOptions}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Select Status"
              />
              {formik.touched.status && formik.errors.status && (
                <small className="p-error">{formik.errors.status}</small>
              )}
            </div>

            {formik.values.status === "3" && ( // Show comments input if rejected
              <div className="field col-12 md:col-12">
                <label htmlFor="comments">
                  Comments <span className="Staric-Custom text-danger"> *</span>
                </label>
                <InputText
                  id="comments"
                  placeholder="Enter Comments"
                  value={formik.values.comments}
                  onChange={formik.handleChange}
                  name="comments"
                />
                {formik.touched.comments && formik.errors.comments ? (
                  <div className="error">{formik.errors.comments}</div>
                ) : null}
              </div>
            )}

            {formik.values.status === "2" && ( // Show upload for approved status
              <div className="field col-12 md:col-12">
                <label>
                  Upload NOC{" "}
                  <span className="Staric-Custom text-danger"> *</span>
                </label>

                <div className="image-uploader-container">
                  <label className="image-upload-button">
                    <input
                      type="file"
                      onChange={handleEmployeeImageChange}
                      style={{ display: "none" }}
                      accept=".pdf"
                    />
                    {/* <button
        style={{border:"none"}}
          className="add-icon"
          onClick={(e) => {
            if (isUploaded) {
              removeEmployeeImage();
            } else {
              e.target.previousSibling.click();
            }
          }}
        >
          {isUploaded ? "-" : "+"}
        </button> */}
                    <div className="add-icon">+</div>
                  </label>
                </div>

                {/* Preview section */}
                {fileName && (
                  <div
                    className="image-preview-container"
                    style={{ marginTop: "20px" }}
                  >
                    <iframe
                      src={URL.createObjectURL(fileName)}
                      title="PDF Preview"
                      className="pdf-preview"
                      style={{
                        width: "100%",
                        height: "300px",
                        border: "none",
                      }}
                    />
                  </div>
                )}
                {formik.errors.nocFile && formik.touched.nocFile && (
                  <small className="p-error">{formik.errors.nocFile}</small>
                )}
              </div>
            )}
          </div>

          <div className="p mt-4 form-buttons">
            <ActionButtons
              loading={loading}
              onCancel={onHide}
              onSave={formik.handleSubmit}
              saveLabel={editable ? "Update Changes" : "Save Changes"}
              showSave={true}
              cancelLabel="Cancel"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default EditAddForm;
