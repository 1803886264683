import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import ActionButtons from "../../components/action_buttons";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { useFormik } from "formik";
import config from "../../../../config";
import { Password } from "primereact/password";
import Spinner from "../../components/spinner";

const EditAddForm = ({
  onHide,
  editable,
  rowData,
  setShowDialog,
  GetEmployee,
}) => {
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [regionData, setRegionData] = useState([]); 
  const [showRegion, setShowRegion] = useState(false); 
  const [showFields, setShowFields] = useState(false); 

  const fetchFilters = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("authToken");

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const options = {
        method: "GET",
        headers,
      };

      // Fetch departments, roles, employees, and regions
      const [
        departmentResponse,
        roleResponse,
        employeeResponse,
        regionResponse,
      ] = await Promise.all([
        fetch(`${config.baseUrl}api/Department/GetAll`, options),
        fetch(`${config.baseUrl}api/RolesPermissions/GetAllRoles`, options),
        fetch(`${config.baseUrl}api/Employee/GetEmployeesNameAndId`, options), // Added employee API call
        fetch(
          `https://dev-petncd-api.appinsnap.com/api/Lov/GetRegions`,
          options
        ), // Added regions API call
      ]);

      if (
        !departmentResponse.ok ||
        !roleResponse.ok ||
        !employeeResponse.ok ||
        !regionResponse.ok
      ) {
        throw new Error("Error fetching filter data");
      }

      // Parse responses to JSON
      const departmentsData = await departmentResponse.json();
      const roleData = await roleResponse.json();
      const employeesData = await employeeResponse.json(); // Parse employee data
      const regionsData = await regionResponse.json(); // Parse regions data

      // Map and set departments
      const departmentOptions = departmentsData.data.map((department) => ({
        label: department.name,
        value: department.id,
      }));
      setDepartments(departmentOptions);

      // Map and set roles
      const roleOptions = roleData.data.map((role) => ({
        label: role.name,
        value: role.id,
        dataVisibilityMode: role.dataVisibilityMode, // Assuming this field exists
      }));
      setRoleData(roleOptions);

      // Map and set employees
      const employeeOptions = employeesData.data.map((employee) => ({
        label: employee.name, // Assuming employee has a 'name' field
        value: employee.id, // Assuming employee has an 'id' field
      }));
      setEmployeeData(employeeOptions); // Set employee data in state

      // Map and set regions
      const regionOptions = regionsData.data.map((region) => ({
        label: region.name, // Assuming region has a 'name' field
        value: region.id, // Assuming region has an 'id' field
      }));
      setRegionData(regionOptions); // Set region data in state

      setLoading(false);
    } catch (error) {
      console.error("Error fetching filters:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Employee name is required"),
    userName: Yup.string()
      .required("Username is required")
      .matches(
        /^[a-zA-Z0-9]+$/,
        "Username must contain only letters and digits"
      ),
    password: editable
      ? Yup.string().optional()
      : Yup.string()
          .required("Password is required")
          .min(8, "Password must be at least 8 characters")
          .matches(
            /[a-z]/,
            "Password must contain at least one lowercase letter"
          )
          .matches(
            /[A-Z]/,
            "Password must contain at least one uppercase letter"
          )
          .matches(
            /[!@#$%^&*(),.?":{}|<>]/,
            "Password must contain at least one special character"
          ),
    email: editable
      ? Yup.string().optional()
      : Yup.string().email("Invalid email").required("Email is required"),
    role: Yup.string()
      .required("Role is required") // Ensure role is always required
      .min(1, "Please select a valid role"), // Adjust min if your roles start from 1
    departmentId: Yup.number()
      .nullable()
      .when("role", {
        is: (role) => {
          const selectedRole = roleData.find((r) => r.value === role);
          return selectedRole?.dataVisibilityMode !== 3; // Department required unless dataVisibilityMode is 3
        },
        then: Yup.number()
          .typeError("Please select a valid department")
          .required("Department is required"),
        otherwise: Yup.number().nullable(),
      }),
    cnic: Yup.string()
      .matches(/^\d{13}$/, "CNIC must be exactly 13 digits")
      .required("CNIC is required"),
    reportingTo: Yup.string().when("role", {
      is: (role) => {
        const selectedRole = roleData.find((r) => r.value === role);
        return selectedRole?.dataVisibilityMode !== 3; // ReportingTo required only if dataVisibilityMode is not 3
      },
      then: Yup.string().required("Reporting To is required"),
      otherwise: Yup.string().nullable(),
    }),
    regionId: Yup.number()
      .nullable()
      .when("role", {
        is: (role) => {
          const selectedRole = roleData.find((r) => r.value === role);
          return selectedRole?.dataVisibilityMode === 2;
        },
        then: Yup.number()
          .typeError("Please select a valid region")
          .required("Region is required"),
        otherwise: Yup.number().nullable(),
      }),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      userName: "",
      password: "",
      email: "",
      role: "",
      departmentId: "",
      cnic: "",
      reportingTo: "",
      regionId: "",
    },

    validationSchema,
    onSubmit: async (data) => {
      const token = localStorage.getItem("authToken");

      try {
        let response;
        setLoading(true);
        if (editable) {
          response = await axios.put(
            `${config.baseUrl}api/Employee/UpdateRegisteredEmployee`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
        } else {
          response = await axios.post(
            `${config.baseUrl}api/Employee/RegisterEmployee`,
            data,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
        }
        if (response.data && response.data.status === 409) {
          const errorMessage = response.data.message || "A conflict occurred.";
          toast.error(errorMessage);
        } else {
          setShowDialog(false);
          GetEmployee();
          if (editable) {
            toast.success("Updated successfully");
          } else {
            toast.success("Employee Registered  successfully");
          }
        }
      } catch (error) {
        setLoading(false);
        if (error.response) {
          const errorMessage =
            error.response.data.message || "Something went wrong";
          toast.error(errorMessage);
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      } finally {
        setLoading(false);
      }
    },
  });

  const handleRoleChange = (e) => {
    const roleId = e.value;
    const selectedRoleData = roleData.find((role) => role.value === roleId);

    // Update Formik field
    formik.setFieldValue("role", roleId);

    // Validate role field
    formik.validateField("role");

    // Show or hide fields based on dataVisibilityMode
    if (selectedRoleData?.dataVisibilityMode === 1) {
      setShowFields(true); // Show fields for mode 1
      setShowRegion(false); // Adjust based on your logic
    } else if (selectedRoleData?.dataVisibilityMode === 2) {
      setShowFields(true); // Hide fields for mode 2
      setShowRegion(true); // Show region fields for mode 2
    } else if (selectedRoleData?.dataVisibilityMode === 3) {
      setShowFields(false); // Hide fields for mode 3
      setShowRegion(false); // Hide region fields for mode 3
    }
  };

  useEffect(() => {
    if (editable && rowData) {
      // Set the form values
      formik.setFieldValue("name", rowData?.name || "");
      formik.setFieldValue("email", rowData?.email || "");
      formik.setFieldValue("role", rowData?.roleId || "1");
      formik.setFieldValue("reportingTo", rowData?.reportingTo || "1");
      formik.setFieldValue("userName", rowData?.userName || "");
      formik.setFieldValue("password", rowData?.password || "");
      formik.setFieldValue("departmentId", rowData?.departmentId || "1");
      formik.setFieldValue("regionId", rowData?.regionId || "1");
      formik.setFieldValue("cnic", rowData?.cnic || "");

      if (rowData?.id) {
        formik.setFieldValue("id", rowData.id);
      }

      // Simulate the role change to trigger field visibility logic
      if (rowData?.roleId) {
        handleRoleChange({ value: rowData.roleId });
      }

      // Manually trigger visibility logic for the department and reporting fields
      const selectedRoleData = roleData.find(
        (role) => role.value === rowData.roleId
      );
      if (selectedRoleData) {
        if (selectedRoleData.dataVisibilityMode === 1) {
          setShowFields(true); // Show fields if mode is 1
        } else {
          setShowFields(false); // Hide fields for other modes
        }
      }
    }
  }, [editable, rowData, roleData]);

  return (
    <div className="main-form">
      {loading && (
        <div className="spinner-overlay">
          <Spinner />
        </div>
      )}
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid formgrid grid">
          <div className="field col-12 md:col-3">
            <label htmlFor="name">
              Employee Name<span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              maxLength={30}
              id="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder="Enter Name"
            />
            {formik.errors.name && formik.touched.name && (
              <small className="p-error">{formik.errors.name}</small>
            )}
          </div>
          <div className="field col-12 md:col-3">
            <label htmlFor="userName">
              User Name<span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              maxLength={10}
              id="userName"
              value={formik.values.userName}
              onChange={formik.handleChange}
              placeholder="Enter Username"
            />
            {formik.errors.userName && formik.touched.userName && (
              <small className="p-error">{formik.errors.userName}</small>
            )}
          </div>
          {!editable && (
            <div className="field col-12 md:col-3 pass-pass">
              <label htmlFor="password">
                Password<span className="Staric-Custom text-danger"> *</span>
              </label>
              <Password
                toggleMask
                feedback={false}
                id="password"
                maxLength={8}
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                placeholder="Enter Password"
              />
              {formik.errors.password && formik.touched.password && (
                <small className="p-error">{formik.errors.password}</small>
              )}
            </div>
          )}
          <div className="field col-12 md:col-3">
            <label htmlFor="cnic">
              CNIC<span className="Staric-Custom text-danger"> *</span>
            </label>
            <InputText
              id="cnic"
              value={formik.values.cnic}
              onChange={formik.handleChange}
              placeholder="Enter CNIC"
              maxLength={13}
            />
            {formik.errors.cnic && formik.touched.cnic && (
              <small className="p-error">{formik.errors.cnic}</small>
            )}
          </div>
          {!editable && (
            <div className="field col-12 md:col-3">
              <label htmlFor="email">
                Email<span className="Staric-Custom text-danger"> *</span>
              </label>
              <InputText
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder="Enter Email"
              />
              {formik.errors.email && formik.touched.email && (
                <small className="p-error">{formik.errors.email}</small>
              )}
            </div>
          )}

          <div className="field col-12 md:col-3">
            <label htmlFor="role">
              Role<span className="Staric-Custom text-danger"> *</span>
            </label>
            <Dropdown
              filter
              id="role"
              name="role"
              value={formik.values.role}
              options={roleData}
              onChange={handleRoleChange} // Custom handler for role change
              onBlur={formik.handleBlur}
              placeholder="Select Role"
            />
            {formik.touched.role && formik.errors.role && (
              <small className="p-error">{formik.errors.role}</small>
            )}
          </div>

          {showFields && (
            <>
              <div className="field col-12 md:col-3">
                <label htmlFor="departmentId">
                  Department
                  <span className="Staric-Custom text-danger"> *</span>
                </label>
                <Dropdown
                  filter
                  id="departmentId"
                  name="departmentId"
                  value={formik.values.departmentId}
                  options={departments}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Select Department"
                />
                {formik.touched.departmentId && formik.errors.departmentId && (
                  <small className="p-error">
                    {formik.errors.departmentId}
                  </small>
                )}
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="reportingTo">
                  Reporting To
                  <span className="Staric-Custom text-danger"> *</span>
                </label>
                <Dropdown
                  filter
                  id="reportingTo"
                  name="reportingTo"
                  value={formik.values.reportingTo}
                  options={employeeData}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Select Reporting To"
                />
                {formik.touched.reportingTo && formik.errors.reportingTo && (
                  <small className="p-error">{formik.errors.reportingTo}</small>
                )}
              </div>
            </>
          )}

          {/* Conditionally show Region */}
          {showRegion && (
            <div className="field col-12 md:col-3">
              <label htmlFor="regionId">
                Region<span className="Staric-Custom text-danger"> *</span>
              </label>
              <Dropdown
                filter
                id="regionId"
                name="regionId"
                value={formik.values.regionId}
                options={regionData}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Select Region"
              />
              {formik.touched.regionId && formik.errors.regionId && (
                <small className="p-error">{formik.errors.regionId}</small>
              )}
            </div>
          )}
        </div>

        <div className="p mt-4 form-buttons">
          <ActionButtons
            loading={loading}
            onCancel={onHide}
            onSave={formik.handleSubmit}
            saveLabel={editable ? "Update Changes" : "Save Changes"}
            showSave={true}
            cancelLabel="Cancel"
          />
        </div>
      </form>
    </div>
  );
};

export default EditAddForm;
